import React from 'react';
import GoogleMapReact from 'google-map-react';
import request from 'request';
import LoadingOverlay from 'react-loading-overlay';

import FacilityPin from '../javascripts/FacilityPin';
import httpRequest from '../javascripts/httpRequest';

const apikey = 'AIzaSyDx8NhxrQwJZ46ofi8qcpTZvcCzoxkH5jw';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class HospitalEdit extends React.Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 17,
  };

  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: props.hospital.latitude,
        lng: props.hospital.longitude,
      },
      userClick: {
        lat: null,
        lng: null,
      },
      hospital: props.hospital,
    };
  }

  _onClick({ x, y, lat, lng, event }) {
    this.setState({ userClick: { lat, lng } });
  }

  _update() {
    const { userClick } = this.state;
    if (!userClick.lat) {
      return;
    }

    var jar = request.jar();
    var header = {};
    var options = {
      url: location.origin + this.props.updatepath,
      method: 'PATCH',
      header: header,
      followAllRedirects: true,
      jar: jar,
      json: {
        latitude: userClick.lat,
        longitude: userClick.lng,
      },
    };
    (async () => {
      const result = await httpRequest(options);
      location.reload();
    })();
  }

  _onChangeTxt(name, txt) {
    ///////this.setState(current => ({ hospital: { ...current.hospital, name: e.target.value } }));
  }

  render() {
    const { center, userClick, hospital } = this.state;
    return (
      <React.Fragment>
        <div>
          <p>
            {this.props.hospital.latitude},{this.props.hospital.longitude}
            {!!userClick.lat && (
              <span>
                -> {userClick.lat},{userClick.lng}
              </span>
            )}
          </p>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <small>名称</small>
          <input
            type="text"
            className="form-control"
            placeholder="name"
            value={hospital.name}
            onChange={e => {
              this._onChangeTxt(e.target.value);
            }}
          />
          <small>現地名称</small>
          <input
            type="text"
            className="form-control"
            placeholder="localname"
            value={hospital.localname || ''}
            onChange={() => {}}
          />
          <div>
            <small>緯度経度</small>
            <div className="row m-0">
              <input
                type="text"
                className="col-3 form-control"
                placeholder="latitude"
                value={hospital.latitude}
                onChange={() => {}}
              />
              <input
                type="text"
                className="ml-2 col-3 form-control"
                placeholder="longitude"
                value={hospital.longitude}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
        <div style={{ height: '500px', width: '100%', boxSizing: 'border-box', border: 'soild 1px #CCC' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: apikey }}
            defaultCenter={center}
            defaultZoom={this.props.zoom}
            onClick={this._onClick.bind(this)}
          >
            <FacilityPin lat={center.lat} lng={center.lng} color="#F99" />
            {!!userClick.lat && <FacilityPin lat={userClick.lat} lng={userClick.lng} color="#F00" />}
          </GoogleMapReact>
        </div>
        <div className="btn btn-info" onClick={this._update.bind(this)}>
          更新する
        </div>
      </React.Fragment>
    );
  }
}

export default HospitalEdit;
