import React from 'react';
import PropTypes from 'prop-types';
class GeneralListItem extends React.Component {
  render() {
    const { general } = this.props;
    return (
      <tr>
        <td className="text-center">{general.id}</td>
        <td>{general.code}</td>
        <td>{general.name_jp}</td>
        <td>{general.name_en}</td>
      </tr>
    );
  }
}

export default GeneralListItem;
