import request from 'request';

export default function httpRequest(options) {
  return new Promise((resolve, reject) => {
    request(options, function(error, response, body) {
      let ret = {
        //headers: response.headers,
        body: body,
        error: error,
        //href: response.request.uri.href,
      };

      if (error) {
        reject(ret);
      } else {
        resolve(ret);
      }
    });
  });
}
