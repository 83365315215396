/* global $ */
import React from 'react';

class MenuToggle extends React.Component {
  navbar_menu_visible = 0;

  _onClick(e) {
    const $toggle = $(e.currentTarget);
    if (this.navbar_menu_visible === 1) {
      $('html').removeClass('nav-open');
      this.navbar_menu_visible = 0;
      setTimeout(() => {
        $toggle.removeClass('toggled');
        $('#bodyClick').remove();
      }, 550);
    } else {
      setTimeout(() => {
        $toggle.addClass('toggled');
      }, 580);

      const div = '<div id="bodyClick"></div>';
      $(div)
        .appendTo('body')
        .click(() => {
          $('html').removeClass('nav-open');
          this.navbar_menu_visible = 0;
          setTimeout(() => {
            $toggle.removeClass('toggled');
            $('#bodyClick').remove();
          }, 550);
        });

      $('html').addClass('nav-open');
      this.navbar_menu_visible = 1;
    }
  }

  render() {
    return (
      <>
        <div className="navbar-toggle">
          <button onClick={this._onClick.bind(this)} type="button" className="navbar-toggler">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
      </>
    );
  }
}

export default MenuToggle;
