import React from 'react';
const pinStyle = {
  width: '10px',
  height: '10px',
  marginLeft: '-5px',
  marginTop: '-5px',
};
const txtStyle = {
  position: 'absolute',
  top: '-26px',
  fontSize: '15px',
  left: '10px',
  background: 'tomato',
  padding: '3px',
  color: 'white',
};
const FacilityPin = props => {
  return (
    <div>
      {!!props.pinname && <p style={txtStyle}>{props.pinname}</p>}
      <div style={{ ...pinStyle, backgroundColor: props.color || '#F00' }} onClick={props.onClick}></div>
    </div>
  );
};
export default FacilityPin;
