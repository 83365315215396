import React from 'react';
import GoogleMapReact from 'google-map-react';
import FacilityPin from '../javascripts/FacilityPin';
const API_KEY = 'AIzaSyD4cZMQqoU_13vvjmUIGdBYWItic5QqJJk';

class CountryDetail extends React.Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 10,
  };
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: props.country.lat,
        lng: props.country.lon,
      },
      selected: null,
    };
    console.info(props);
  }
  render() {
    const { hospitals } = this.props;
    const { center, selected } = this.state;
    return (
      <div
        style={{
          height: '700px',
          width: '100%',
          boxSizing: 'border-box',
          border: 'soild 1px #CCC',
          position: 'relative',
        }}
      >
        {!!hospitals.length && (
          <React.Fragment>
            <GoogleMapReact bootstrapURLKeys={{ key: API_KEY }} defaultCenter={center} defaultZoom={this.props.zoom}>
              {hospitals.map(hospital => {
                return (
                  <FacilityPin
                    key={hospital.name}
                    lat={hospital.latitude}
                    lng={hospital.longitude}
                    color="#F00"
                    onClick={() => {
                      console.info(hospital);
                      this.setState({ selected: hospital });
                    }}
                  />
                );
              })}
            </GoogleMapReact>
            {!!selected && (
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    opacity: 0.8,
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    position: 'absolute',
                  }}
                ></div>
                <p style={{ margin: '20px', position: 'relative' }}>
                  <a target="_blank" href={MA_WEBROOT + '/admin/hospitals/' + selected.id}>
                    {selected.name}
                  </a>
                </p>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CountryDetail;
