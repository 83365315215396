import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import request from 'request';
import GoogleMapReact from 'google-map-react';
import LoadingOverlay from 'react-loading-overlay';

import httpRequest from '../javascripts/httpRequest';
import FacilityPin from '../javascripts/FacilityPin';
import AsyncUpload from '../javascripts/asyncUpload';
// const API_KEY = 'AIzaSyDx8NhxrQwJZ46ofi8qcpTZvcCzoxkH5jw';
const API_KEY = 'AIzaSyD4cZMQqoU_13vvjmUIGdBYWItic5QqJJk';
const asyncUpload = new AsyncUpload();

class HelloMessage extends React.Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 14,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...props.hospital,
      place_id: props.hospital.placeId,
      serachval: props.hospital.name,
      items: [],
      start: 1,
      loading: false,
    };
  }

  _onClick() {
    const { start, serachval } = this.state;
    (async () => {
      var header = {};
      var data = {
        input: serachval,
      };
      var options = {
        url: MA_WEBROOT + 'api/ext/findplacefromtext',
        method: 'GET',
        header: header,
        followAllRedirects: true,
        qs: data,
        json: true,
      };
      const detail = await httpRequest(options);

      if (!detail.body.status || !detail.body.candidates[0]) {
        alert(detail.body.status);
        return;
      }
      const res = detail.body.candidates[0];
      const place_id = res.place_id;
      this.setState({ ...res });
    })();
  }

  _onClickLoadImg(loadimg = true) {
    (async () => {
      const { place_id } = this.state;
      var options = {
        url: MA_WEBROOT + 'api/ext/detail',
        method: 'GET',
        followAllRedirects: true,
        qs: { place_id },
        json: true,
      };
      const detail = await httpRequest(options);
      const { result } = detail.body;
      const items = [];
      if (loadimg) {
        if (result.photos && result.photos[0]) {
          result.photos.forEach(photo => {
            items.push({
              link:
                'https://maps.googleapis.com/maps/api/place/photo?key=' +
                API_KEY +
                '&photoreference=' +
                photo.photo_reference +
                '&maxwidth=800',
            });
          });
        } else {
          alert('画像が見つかりません');
        }
        this.setState({ items, ...result });
      } else {
        this.setState({ ...result });
      }
    })();
  }

  _onChange(e) {
    this.setState({ serachval: e.target.value });
  }

  _imgClick(e) {
    const { imglink } = e.currentTarget.dataset;
    if (!imglink) {
      return;
    }
    confirmAlert({
      title: '病院画像登録',
      message: 'この画像を登録しますか？',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var jar = request.jar();
            var header = {};
            var options = {
              url: location.href,
              method: 'PATCH',
              header: header,
              followAllRedirects: true,
              jar: jar,
              json: { imglink },
            };
            this.setState({ loading: true });
            (async () => {
              const result = await httpRequest(options);
              location.reload();
            })();
          },
        },
        {
          label: 'No',
          onClick: () => {
            console.info('no');
          },
        },
      ],
    });
  }

  async _onClickSave() {
    const data = await asyncUpload.addPhoto('hospitalimg-' + this.state.id);
    if (data && data.Location) {
      var jar = request.jar();
      var header = {};
      var options = {
        url: location.href,
        method: 'PATCH',
        header: header,
        followAllRedirects: true,
        jar: jar,
        json: data,
      };
      const result = await httpRequest(options);
      location.reload();
    }
  }

  _onClickSavePID() {
    const { place_id } = this.state;
    this._updateHospital({ place_id });
  }

  async _onClickSaveReview(data) {
    const { reviews } = this.state;
    this._updateHospital({ reviews });
  }

  _onClickSaveRating() {
    const { rating } = this.state;
    this._updateHospital({ rating });
  }

  _onClickSavePos() {
    const { geometry } = this.state;
    this._updateHospital({
      latitude: geometry.location.lat,
      longitude: geometry.location.lng,
    });
  }

  async _updateHospital(data) {
    this.setState({ loading: true });
    const { reviews } = this.state;
    var jar = request.jar();
    var header = {};
    var options = {
      url: location.href,
      method: 'PATCH',
      header: header,
      followAllRedirects: true,
      jar: jar,
      json: data,
    };
    (async () => {
      const result = await httpRequest(options);
      this.setState({ loading: false });
      if (result.body && result.body.status === 400) {
        alert(result.body.message);
      }
    })();
  }

  render() {
    const {
      loading,
      name,
      items,
      serachval,
      geometry,
      place_id,
      reviews,
      rating,
      latitude,
      longitude,
      formatted_address,
    } = this.state;
    return (
      <LoadingOverlay active={loading} spinner text="loading...">
        <div className="card">
          <div className="card-body">
            <div>
              <p>
                <b>Place ID</b> :: {place_id}
              </p>
              <div className="row">
                <div className="col">
                  <input className="form-control" type="text" value={serachval} onChange={this._onChange.bind(this)} />
                  <button className="btn btn-info" type="button" onClick={this._onClick.bind(this)}>
                    PLACE ID を取得
                  </button>
                  <p style={{ color: '#00F' }}>
                    now: {latitude} : {longitude}
                  </p>
                  <hr />
                  {!!geometry && (
                    <div>
                      <p style={{ color: '#F00' }}>
                        new: {geometry.location.lat} : {geometry.location.lng}
                      </p>
                      <p>{formatted_address}</p>
                    </div>
                  )}
                </div>
                <div className="col-8">
                  {!!geometry && (
                    <div>
                      <div
                        style={{ height: '300px', width: '100%', boxSizing: 'border-box', border: 'soild 1px #CCC' }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: API_KEY }}
                          defaultCenter={geometry.location}
                          defaultZoom={this.props.zoom}
                        >
                          <FacilityPin lat={latitude} lng={longitude} color="#00F" />
                          <FacilityPin {...geometry.location} color="#F00" pinname={place_id} />
                        </GoogleMapReact>
                      </div>
                      <button className="btn btn-success" type="button" onClick={this._onClickSavePos.bind(this)}>
                        位置情報を更新
                      </button>
                      <button className="btn btn-success" type="button" onClick={this._onClickSavePID.bind(this)}>
                        PLACE IDを保存
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div>
                <h5>レーティング</h5>
                {!!rating && (
                  <div>
                    <p>{rating}</p>
                    <button className="btn btn-success" type="button" onClick={this._onClickSaveRating.bind(this)}>
                      レーティングを保存
                    </button>
                  </div>
                )}
                {!rating && <p>レーティングを取得できていません</p>}
              </div>
              <hr />
              <div className="thumnail-container">
                <h5>画像の登録</h5>
                {!place_id && (
                  <p>
                    <small>PLACE IDを取得すると画像が検索できます。</small>
                  </p>
                )}
                {!!place_id && (
                  <button className="btn btn-info" type="button" onClick={this._onClickLoadImg.bind(this)}>
                    googleから画像を取得
                  </button>
                )}
                <div className="boxContainer">
                  {!!items.length &&
                    items.map((item, index) => {
                      return (
                        <div
                          className="boxwrap"
                          key={'photo' + index}
                          onClick={this._imgClick.bind(this)}
                          data-imglink={item.link}
                        >
                          <img className="box" src={item.link} alt="" />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div>
                <p>PCから画像を登録</p>
                <input id="photoupload" type="file" accept="image/*" />
                <button className="btn btn-success" type="button" onClick={this._onClickSave.bind(this)}>
                  画像を保存
                </button>
              </div>
              <hr />
              <div className="thumnail-container">
                <h5>Review</h5>
                {!place_id && (
                  <p>
                    <small>PLACE IDを取得するとレビューが取得できます</small>
                  </p>
                )}
                {!!place_id && (
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={() => {
                      this._onClickLoadImg(false);
                    }}
                  >
                    レビューを取得
                  </button>
                )}
                {!!reviews && (
                  <div>
                    <div className="list-group">
                      {reviews.map(review => {
                        return (
                          <div className="list-group-item list-group-item-action" key={'review' + review.time}>
                            <div className="d-flex w-100 justify-content-between">
                              <small>{review.relative_time_description}</small>
                            </div>
                            <p className="mb-1">{review.text}</p>
                            <small>rating: {review.rating}</small>
                          </div>
                        );
                      })}
                    </div>
                    <button className="btn btn-success" type="button" onClick={this._onClickSaveReview.bind(this)}>
                      レビューを保存
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default HelloMessage;
