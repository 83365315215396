const AWS = require('aws-sdk');

//const s3 = new AWS.S3();
//
var bucketName = 'locationimage';
var bucketRegion = 'ap-northeast-1';
var IdentityPoolId = 'ap-northeast-1:99d1c8f7-cccc-439c-8865-1dee9e2045ac';
AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
  }),
});

var s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketName },
});

class AsyncUpload {
  constructor() {}

  list() {
    s3.listObjects({ Delimiter: '/' }, function(err, data) {
      if (err) {
        return alert('There was an error listing your albums: ' + err.message);
      } else {
        console.info(data);
      }
    });
  }

  addPhoto(key) {
    var files = document.getElementById('photoupload').files;
    if (!files.length) {
      return alert('Please choose a file to upload first.');
    }
    var file = files[0];
    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: key + new Date().getTime(),
        Body: file,
        ACL: 'public-read',
        ContentType: file.type || 'image/png',
      },
    });
    return upload.promise();
  }

  downloadURI(uri, filename, callback) {
    /*
    request(url)
      .pipe(fs.createWriteStream(filename))
      .on('close', function() {
        callback(filename);
        });*/
    var xhr = new XMLHttpRequest();
    xhr.open('GET', uri);
    xhr.responseType = 'blob';
    xhr.onloadend = function() {
      if (xhr.status !== 200) {
        return false;
      }
      //window.navigator.msSaveOrOpenBlob(xhr.response, fileName);
      console.info(xhr.response);
    };
    xhr.send();
  }
}

module.exports = AsyncUpload;
